<template>
  <a
    :href="`/cinema/${cinema.key}`"
    class="cinema_list__item pointer"
    @click.prevent="navigateTo({ name: 'cinema-name', params: { name: cinema.key } })"
  >
    <Icon
      :icon="`img/vendor_${cinema.key}`"
      :is-local="false"
      :class="classes"
      :style="{ 'background-color': cinema.color }"
      :alt="cinema.key"
      @mouseover="toggleHover"
      @mouseleave="toggleHover"
    />
  </a>
</template>
<script setup>
import Icon from '@/components/common/icons/icon.vue';

defineProps({
  cinema: {
    type: Object,
    required: true,
  },
});

const classes = shallowRef({
  cinema_list__icon: true,
  unhover: true,
});

function toggleHover() {
  classes.value = {
    ...classes.value,
    unhover: !classes.value.unhover,
  };
}
</script>
