<template>
  <div
    v-for="row in cinemaList2"
    :key="row.valueOf()"
    class="cinema_list__row"
  >
    <CinemaListItem
      v-for="cinema in row"
      :key="cinema.key"
      :cinema="cinema"
      class="cinema_list__item"
      :class="`cinema_list__count_${row.length}`"
    />
  </div>
</template>

<script setup>
import CinemaListItem from '@/components/ui/Cinemas/CinemaListItem.vue';

const { cinemaList } = storeToRefs(useVendorStore());
const cinemaList2 = shallowRef([]);
splitArrayIntoLines(cinemaList.value);

// разбиваем массив на строки
function splitArrayIntoLines(arr) {
  if (arr.length <= 3) {
    // Если в массиве 3 элемента или менее, добавляем его в одну строку
    cinemaList2.value.push(arr);
  } else {
    // Если в массиве больше 3 элементов, разбиваем его на строки с двумя или тремя элементами
    const firstLineLength = arr.length % 3 === 0 ? 3 : 2;
    cinemaList2.value.push(arr.slice(0, firstLineLength));
    splitArrayIntoLines(arr.slice(firstLineLength));
  }
}
</script>
